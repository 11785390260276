import * as React from "react";
import MainTextComponent from "../../components/maintext";
import NavBar from "../../components/navbar";
import CardPage from "../card";
import HowItWorksPage from "../howitworks";
import FooterPage from "../footer";
import LessCodeComponent from "../../components/lesscode";

const IndexPage = () => {
  return <div>
    <NavBar />
    <MainTextComponent />
    <CardPage/>
    <HowItWorksPage />
    <LessCodeComponent />
    <FooterPage />
  </div>

}

export default IndexPage

import * as React from "react";
import styled from "styled-components";
import check from '../../images/check.png';



const CardWrapper = styled.div`
background: ${props => props.theme.color.white.regular};
color: ${props => props.theme.color.black.regular};
flex:1;
max-width: 350px;
text-align: center;
padding: 15px 20px 40px 20px;
${props=>props.borderRight?'border-right: 1px ridge #999;':''}
`;


const Check = styled.img`
width: 80px;
height: 60px;
padding: 0px;
transition: 0.1s ease-in;
:hover{
  padding: 3px;
}
`;

const CardComponent = ({ title, subtext, borderRight }) => {
  return <CardWrapper borderRight={borderRight}>
    <div>
      <Check src={check}  alt="Check" />
    </div>
    <h4 style={{ lineHeight: 0 }}>
      {title}
    </h4>
    <div>
      <span>{subtext}</span>
    </div>
  </CardWrapper>
}

export default CardComponent;

import * as React from "react";
import styled from "styled-components";
import customize from '../../images/customize.png';
import server from '../../images/server.png';
import sheets from '../../images/sheets.png';
import { Flex } from "../maintext/styles";
import { Container } from "./styles";

const TitleNumber = styled.div`
display: flex;
align-items: center;
 h1{
   color: ${props => props.theme.color.black.regular};
   padding: 5px 10px;
}
h4{
    color: ${props => props.theme.color.verde};
}
`;

const WrapperStep = styled.div`
color: ${props => props.theme.color.black.light};
max-width:400px;
padding: 0px 50px;
`;

const CenterFlex = styled(Flex)`
align-items: center;
justify-content: center;
margin: 50px 5vw;
flex-wrap: wrap-reverse;

`;

const HowItWorksComponent = () => {
  return <Container>
    <CenterFlex>
      <div>
        <img alt="Sheets" src={sheets} width={300} />
      </div>
      <WrapperStep>
        <TitleNumber>
          <h1>1</h1>  <h4>Bring your sheet</h4>
        </TitleNumber>
        <p>
        Bring up your sheet, be it Excel, Libreoffice or Google Docs files. Upload them in your Google Drive account, and link it with APISheet.co. We will make sure everything is right to use it as a Database for your API.
        </p>
      </WrapperStep>
    </CenterFlex>
    <CenterFlex style={{flexWrap:'wrap'}}>
      <WrapperStep>
        <TitleNumber>
          <h1>2</h1>  <h4>Set your preferences</h4>
        </TitleNumber>
        <p>
        Choose what you need for your project. If it's a production API, you may want to turn <b>logging</b> on, to investigate what's happenning. If you need fast results, <b>caching</b> is a must. For protecting your Endpoints, make sure <b>authorization</b> is required.
        </p>
      </WrapperStep>
      <div>
        <img alt="Customize" src={customize} width={250} />
      </div>
    </CenterFlex>
    <CenterFlex>
      <div>
        <img alt="Servers" src={server} width={300} />
      </div>
      <WrapperStep>
        <TitleNumber>
          <h1>3</h1>  <h4>Your API is ready</h4>
        </TitleNumber>
        <p>
        And that's it. Your API is ready. Grab your endpoint and start firing up requests.
        </p>
      </WrapperStep>
    </CenterFlex>
  </Container>
}

export default HowItWorksComponent;

import * as React from "react"
import Layout from "../common/layout"
import IndexPage from "../views/index"

const Index = () => {
  return (
    <Layout >
      <title>APISheet.co - Give your spreadsheets suporpower!</title>
        <IndexPage />
    </Layout>

  )
}

export default Index

import { Link } from "gatsby";
import * as React from "react";
// import think from '../../images/think.png';
import { Flex } from "../maintext/styles";
import styled from "styled-components";
import { GreenButton, OutlinedGreenButton } from "../../styles/globalstyles";
import ThinkingIcon from "../../images/vectors/thinking";

const YellowFlex = styled(Flex)`
background-color:#fffaff;
`;

const GreenText = styled.div`
color: ${props => props.theme.color.verde};
h4{
  line-height:0px;
  
}
h1{
  line-height:5px;
  letter-spacing:2px;
  color: ${props => props.theme.color.black.regular};
}
`;
const LessCodeComponent = () => {
  return <React.Fragment>
    <YellowFlex>


      <YellowFlex style={{ paddingTop: '10px', flexWrap:'wrap' }}>

        <GreenText>
          <h4>With APISheet<span style={{color:'#e8ce95'}}>.</span>co you can </h4>
          <h1 style={{paddingLeft:20}}>Build More</h1> 
          <h1 style={{paddingLeft:0}}>& Code Less</h1> 
          <p style={{maxWidth:'350px'}}>
            Easily colaborate with non-tech people using <b>spreadsheets</b>
          </p>
          </GreenText>
          <ThinkingIcon width={500} height={300}/>
      </YellowFlex>


      <YellowFlex>
      </YellowFlex>

    </YellowFlex>
    <YellowFlex style={{ padding: '40px', justifyContent: 'space-evenly' }}>
      <a href="https://console.apisheet.co" style={{ textDecoration: 'none' }}>
        <GreenButton>Start Now</GreenButton>
      </a>
      <Link to="usecases" style={{ textDecoration: 'none' }}>
        <OutlinedGreenButton>Use Cases</OutlinedGreenButton>
      </Link>
    </YellowFlex>
  </React.Fragment>
}

export default LessCodeComponent;

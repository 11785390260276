import * as React from "react";
import styled from "styled-components";
import HowItWorksComponent from "../../components/howitworks";
import { Flex } from "../../components/maintext/styles";

const WrapperCenterText = styled.div`
flex:1;
margin-top: 100px;
`;
const CenterText = styled.h1`
text-align: center;
color: ${props => props.theme.color.black.regular};
`;

const HowItWorksPage = () => {
  return <div id="howitworks" style={{ padding: '0 40px' }}>
    <Flex >
      <WrapperCenterText>
        <CenterText>How it works</CenterText>
      </WrapperCenterText>
    </Flex>
    <HowItWorksComponent />
  </div>
}

export default HowItWorksPage;

import React from "react";

function ThinkingIcon({width, height}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox={`0 0 1019.484 436.681`}
    >
      <path
        fill="#ffb9b9"
        d="M314.028 475.274a9.75 9.75 0 10-19.407 1.282l-28.015 36.686a13.583 13.583 0 001.836 14.914l2.198 2.563 10.083-2.016 11.428-10.084-1.345-11.427 14.117-22.184-.019-.016a9.743 9.743 0 009.124-9.718z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M30.041 422.968L25.468 405.984 88.8 380.265 95.549 405.331 30.041 422.968z"
      ></path>
      <path
        fill="#2f2e41"
        d="M105.002 663.391L90.258 608.63l.693-.186a22.075 22.075 0 0127.054 15.575v.001l9.005 33.447z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#ffb8b8"
        d="M117.278 420.254L103.054 409.91 136.185 350.121 157.179 365.388 117.278 420.254z"
      ></path>
      <path
        fill="#2f2e41"
        d="M201.137 668.34l-45.866-33.355.422-.58a22.075 22.075 0 0130.834-4.87h.001l28.014 20.373zM328.45 568.364l-35.795-34.773a18.076 18.076 0 00-23.668-1.322L201.4 607.352l6.05 9.411 64.533-43.694 43.694 57.139 41.678-20.839z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#ffb9b9"
        d="M312.99 575.758l-26.217-2.017-18.986-4.602a9.753 9.753 0 10-1.85 12.655l-.003.014 11.428 4.706 49.072 16.805 6.05-4.705z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#2f2e41"
        d="M285.92 416.699L271.131 426.11 253.653 426.11 172.315 395.86 53.332 417.371 49.971 391.826 158.871 344.099 254.326 375.693 285.92 416.699z"
      ></path>
      <circle cx="254.326" cy="227.132" r="26.217" fill="#ffb9b9"></circle>
      <path
        fill="#ccc"
        d="M412.417 563.463a150.631 150.631 0 01-7.387 46.592l-1.963 6.036-9.411 43.022-10.084 2.69-7.394-9.412-14.789-10.755-10.755-17.478-9.223-15.367-2.877-4.8-10.084-50.416-38.316-21.511-12.772-2.017 2.689-7.394 11.427-15.461 11.428-1.345 3.361-2.689 2.689 1.345 14.823 5.71 48.056-20.304 7.032 1.15 24.2 20.166a150.5 150.5 0 019.35 52.238z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        opacity="0.1"
        style={{ isolation: "isolate" }}
        d="M314.825 325.949L289.281 342.754 251.153 377.131 248.276 372.332 240.209 331.999 242.226 331.999 263.065 293.683 314.825 325.949z"
      ></path>
      <path
        fill="#ccc"
        d="M263.065 289.649L242.226 327.965 230.126 341.41 219.371 344.099 248.276 375.693 289.281 338.721 314.825 321.915 263.065 289.649z"
      ></path>
      <path
        fill="#2f2e41"
        d="M324.193 439.299l-2.841 1.093a3.965 3.965 0 01-1.06-5.507l.046-.067-5.249-.246a39.637 39.637 0 0159.174-4.77c.239-.823 2.844.779 3.907 2.402.358-1.339 2.8 5.136 3.664 9.712.4-1.524 1.938.937.591 3.297.854-.125 1.24 2.06.578 3.276.934-.438.777 2.169-.236 3.911 1.333-.118-.113 27.332-20.113 25.332-1.393-6.398-1-6-2.64-14.227-.763-.81-1.599-1.548-2.433-2.284l-4.513-3.983c-5.247-4.632-10.02-10.349-17.01-12.08-4.805-1.19-7.842-1.458-5.224-6.871-2.365.987-4.574 2.455-6.96 3.371.031-.756.365-1.603.319-2.36z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <circle cx="267.434" cy="230.157" r="4.706" fill="#ffb9b9"></circle>
      <path
        fill="#e6e6e6"
        d="M137.514 351.376H159.12400000000002V372.986H137.514z"
        transform="rotate(-86.19 -20.61 294.586)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#ccc"
        d="M124.344 362.131l26.182 1.744-1.743 26.183-26.183-1.744zm24.968 2.807l-23.906-1.592-1.592 23.906 23.906 1.592z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M241.009 288.772H262.61899999999997V310.382H241.009z"
        transform="rotate(-12.127 -883.757 608.6)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#ccc"
        d="M245.278 276.51l5.513 25.655-25.655 5.512-5.513-25.655zm4.158 24.78l-5.033-23.425-23.425 5.033 5.033 23.424z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M226.603 392.674H248.21300000000002V414.284H226.603z"
        transform="rotate(-43.127 -100.818 401.844)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#ccc"
        d="M253.815 385.997l-19.152 17.938-17.939-19.151 19.152-17.939zm-19.1 16.326l17.487-16.38-16.379-17.485-17.486 16.379z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#3f3d56"
        d="M1053.535 231.66H414.152a17.598 17.598 0 00-17.598 17.598V605.51a17.599 17.599 0 0017.598 17.599h639.383a17.599 17.599 0 0017.599-17.599V249.258a17.599 17.599 0 00-17.599-17.599z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path fill="#fff" d="M329.89 31.101H957.281V385.014H329.89z"></path>
      <circle cx="643.049" cy="15.014" r="6.435" fill="#fff"></circle>
      <path
        fill="#f2f2f2"
        style={{ isolation: "isolate" }}
        d="M777.858 385.015L329.89 385.015 329.89 31.102 777.858 385.015z"
      ></path>
      <circle cx="428.058" cy="298.224" r="60.307" fill="#00bf3b"></circle>
      <path
        fill="#3f3d56"
        d="M531.74 575.21a75.016 75.016 0 1175.017-75.016 75.016 75.016 0 01-75.016 75.016zm0-149.051a74.035 74.035 0 1074.036 74.035 74.035 74.035 0 00-74.035-74.035z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path fill="#3f3d56" d="M753.437 214.97H812.042V227.216H753.437z"></path>
      <path fill="#3f3d56" d="M697.991 148.627H867.488V153.875H697.991z"></path>
      <path fill="#3f3d56" d="M697.991 169.246H867.488V174.494H697.991z"></path>
      <path fill="#3f3d56" d="M697.991 189.866H867.488V195.114H697.991z"></path>
      <path
        fill="#00bf3b"
        d="M888.401 52.282H912.8929999999999V76.774H888.401z"
      ></path>
      <path
        fill="#ccc"
        d="M1014.522 319.804h-29.74v-29.74h29.74zm-28.447-1.293h27.154v-27.154h-27.154z"
        transform="translate(-90.258 -231.66)"
      ></path>
      <path
        fill="#3f3d56"
        d="M1088.75 610.24h-61.23v-4.412a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.874v4.412h-13.12v-4.411a.875.875 0 00-.875-.875H969.79a.875.875 0 00-.874.875v4.411h-13.12v-4.411a.875.875 0 00-.876-.875h-20.993a.875.875 0 00-.874.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.874v4.412h-13.12v-4.411a.875.875 0 00-.875-.875H862.2a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875H647.023a.875.875 0 00-.874.875v4.411h-13.12v-4.411a.875.875 0 00-.876-.875h-20.992a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.875v4.411h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.874v4.412h-13.12v-4.411a.875.875 0 00-.875-.875h-20.993a.875.875 0 00-.875.875v4.411h-40.236a20.993 20.993 0 00-20.993 20.993v9.492a20.993 20.993 0 0020.993 20.993h698.014a20.993 20.993 0 0020.993-20.992v-9.493a20.993 20.993 0 00-20.993-20.993z"
        transform="translate(-90.258 -231.66)"
      ></path>
    </svg>
  );
}

export default React.memo(ThinkingIcon);

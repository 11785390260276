import { Link } from "gatsby";
import * as React from "react";
import { OutlinedButton, StandardButton } from "../../styles/globalstyles";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Flex, FlexMargin, MainText, StyledContainer, SubText } from "./styles";


const MainTextComponent = () => {
  return  <StyledContainer>
      <div style={{paddingTop:'220px'}}>
        <FlexMargin >
          <MainText>Give your spreadsheets <br /><b>Superpower!</b></MainText>
        </FlexMargin>
        <Flex>
          <SubText>Transform your Google Sheets in fully working REST APIs <br />
          CRUD operations, pagination, caching, and everything an API needs<br />
Leverage the power of <b>#nocode</b></SubText>
        </Flex>
        <Flex style={{ justifyContent: 'space-evenly', marginTop: '50px' }}>
          <Link to="signup">
            <StandardButton>Try for free</StandardButton>
          </Link>
          <AnchorLink to="/#howitworks" title="How it works">
            <OutlinedButton>Learn more</OutlinedButton>
            </AnchorLink>
        </Flex>
      </div>
    </StyledContainer>
}

export default MainTextComponent;

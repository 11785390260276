import * as React from "react";
import CardComponent from "../../components/card";
import { Flex } from "../../components/maintext/styles";
import styled from "styled-components";

const FlexCenterAround = styled(Flex)`
justify-content: center;
margin-top: -80px;
`;

const WrapperCard = styled.div`
margin: 0 auto;
width: fit-content;
-webkit-box-shadow: 0px 0px 39px 0px rgba(110,110,110,1);
-moz-box-shadow: 0px 0px 39px 0px rgba(110,110,110,1);
box-shadow: 0px 0px 39px 0px rgba(110,110,110,1);5);
`;

const CardPage = () => {
  return <div>
    <WrapperCard>
      <FlexCenterAround >
        <CardComponent borderRight={true} title={`Organizations`} subtext={`Group your members in organizations and stay organized`} />
        <CardComponent borderRight={true} title={`Caching`} subtext={`Enable caching for blazing fast results, for those with no time to lose`} />
        <CardComponent title={`Easy to use`} subtext={`Get done faster with #nocode and our super intuitive UI`} />
      </FlexCenterAround>
    </WrapperCard>
  </div>
}

export default CardPage
